@font-face {
  font-family: 'myFont';
  src: url('assets/social-media-icons/Akira\ Expanded\ Demo.otf')
}
@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);


.App {
  text-align: center;
  color: black;

}

* {
  box-sizing: border-box;
  font-family: 'myFont' !important;
}

body {
  overflow: hidden;
}


/* .overlay {
  opacity: 0.85;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
} */

.moving-background {
  z-index: -1;
  position: absolute;
  background-size: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  right: 0;
  bottom: 0;
  background-image: url("assets/social-media-icons/BG.png");
  background-repeat: no-repeat;
  background-position: center center;
  object-fit: cover;
  background-attachment: fixed;
  -moz-background-size: cover;
  /* -webkit-background-size: cover; */
  /* background-size: cover; */
  -o-background-size: cover;
}
.moveDown {
  position: absolute;
  top: 38vh;
  top: 54%;
  left: 50%;
  transform: translate(-50%,-50%)
  
}

.audio {
  position: fixed; 
  right: 0px; 
  bottom: 0px; 
  z-index: 10;
}
.moveRight {
  margin-left: 80px;
}
.moveLeft {
  margin-left: 22vh;
}
@media screen and  (min-width:600px) 
{

}
